import { AppLayout } from 'components';
import {
  ActivatedOffers,
  CardDetails,
  Cards,
  CompareRewards,
  Home,
  Landing,
  MySavingsOffers,
  NotFound,
  Offers,
  Premium,
  RedeemHistory,
  MissedRewards,
  EarnedRewards,
  Profile,
  TermPage,
  PolicyPage,
  ContactUs,
  OfferDetails,
  MissedRewardsDetails,
  EarnedRewardsDetails,
  ProfileDelete,
  MissedReportIssue,
  EarnedReportIssue,
  EmailRating,
  EmailRatingThank,
  UserSurvey,
  SpentByCategory,
  SpentByCategoryDetail,
  SpentByMerchantDetails,
  UninstallExtension,
  ExtensionThanks,
  PlaidOAuth,
  LinkedAccount,
  CardValidateSelection,
  CardValidation
} from 'pages';
import { Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from './PrivateRoute';
import { memo } from 'react';
import { useAuthStore } from 'store';
import EnableExtension from 'pages/ExtensionPages/EnableExtension';
import StopUsingWeb from 'pages/ExtensionPages/StopUsingWeb';
import LoginExtension from 'pages/LoginExtension';

export const AppRoute = memo(() => {
  const { user } = useAuthStore();
  const isShowMySavings = user?.hasLinkedPlaid || user?.inactiveUserHistory;

  return (
    <AppLayout>
      <Routes>
        <Route element={<ProtectedRoute redirectPath="/landing" />}>
          <Route path="/" element={<Home />} />
          <Route path="/plaid-oauth" element={<PlaidOAuth />} />
          <Route path="linked-account">
            <Route path="/linked-account" element={<LinkedAccount />} />
            <Route path="/linked-account/card-validation" element={<CardValidation />} />
            <Route path="/linked-account/card-selection/:accountId" element={<CardValidateSelection />} />
          </Route>
          <Route path="offers">
            <Route path="/offers/" element={<Offers />} />
            <Route path="/offers/details/:offerName" element={<OfferDetails />} />
            {isShowMySavings && (
              <>
                <Route path="/offers/my-savings" element={<MySavingsOffers />} />
                <Route path="/offers/redeem-history" element={<RedeemHistory />} />
              </>
            )}
            <Route path="/offers/redeem-history" element={<Offers />} />
          </Route>
          <Route path="premium">
            <Route path="/premium/" element={<Premium />} />
            <Route path="/premium/activated-offers" element={<ActivatedOffers />} />
          </Route>
          <Route path="cards">
            <Route path="/cards/" element={<Cards />} />
            <Route path="/cards/:cardId" element={<CardDetails />} />
            <Route path="/cards/compare-rewards/:merchantName" element={<CompareRewards />} />
          </Route>
          <Route path="/rewards-missed" element={<MissedRewards />} />
          <Route path="/rewards-missed-detail/:category" element={<MissedRewardsDetails />} />
          <Route path="/rewards-missed/report-issue/:category/:transactionId" element={<MissedReportIssue />} />
          <Route path="/rewards-earned" element={<EarnedRewards />} />
          <Route path="/rewards-earned-detail/:id" element={<EarnedRewardsDetails />} />
          <Route path="/rewards-earned/report-issue/:cardId/:transactionId" element={<EarnedReportIssue />} />
          <Route path="profile">
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/delete" element={<ProfileDelete />} />
          </Route>
          <Route path="/user-survey" element={<UserSurvey />} />
          <Route path="/spent-by-category" element={<SpentByCategory />} />
          <Route path="/spent-by-category-detail/:category" element={<SpentByCategoryDetail />} />
          <Route path="/spent-by-merchant-detail/:type/:merchant" element={<SpentByMerchantDetails />} />
        </Route>
        <Route path="/terms-and-conditions" element={<TermPage />} />
        <Route path="/privacy-policy" element={<PolicyPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="landing" element={<Landing />} />
        <Route path="rating" element={<EmailRating />} />
        <Route path="rating/thanks" element={<EmailRatingThank />} />
        <Route path="uninstall-extension" element={<UninstallExtension />} />
        <Route path="uninstall-extension/thanks" element={<ExtensionThanks />} />
        <Route path="enable-extension" element={<EnableExtension />} />
        <Route path="stop-using-web" element={<StopUsingWeb />} />
        <Route path="login-extension" element={<LoginExtension />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AppLayout>
  );
});
