import { ModalCustom } from 'components/ModalCustom';
import './style.scss';
import moment from 'moment';
import { useState } from 'react';
import checkIcon from 'assets/images/green-check-icon.svg';
// import checkIcon from 'assets/images/uthrive-icon-success.png';
import { get } from 'lodash';
import { suggestedOfferType } from 'types';
type props = {
  isOpen: boolean;
  onClose: () => void;
  data: suggestedOfferType | null;
};

export const ModalViewSuggestedOffer = ({ isOpen, onClose, data }: props) => {
  const [showMore, setShowMore] = useState(false);

  const handleResetRM = () => {
    setShowMore(false);
    onClose();
  };

  if (!data) return null;

  // const cardUrl = get(data, 'mappedCard.image.url') || get(data, 'institution.image.url');
  // const cardName = `${
  //   get(data, 'mappedCard.mappedCard') || get(data, 'institution.institutionName')
  // } - ${get(data, 'cardMask')}`;
  // const offerUrl = data?.offerLogo;
  // const storeUrl = 'storeLogo' in data ? data?.storeLogo : '';
  // const offerName = 'offerMerchant' in data && data?.offerMerchant ? data?.offerMerchant : data?.offerName;
  // const offerLang = 'offerShortDescription' in data ? data?.offerShortDescription : data?.earnOfferLang;
  // const createdAt = 'createdAt' in data ? data?.createdAt : '';
  // const offerExpiryDate = 'offerExpiryDate' in data ? data?.offerExpiryDate : '';
  // const offerDescription = 'offerDescription' in data ? data?.offerDescription : data?.offerLongDescription;
  const dateLeft = moment(data?.offerExpiryDate).diff(moment().startOf('day'), 'days');
  // const storeName = 'storeName' in data ? `${data?.storeName} - Use any card or cash` : '';
  // const isActive = true;

  return (
    <ModalCustom isOpen={isOpen} toggle={handleResetRM} onClose={handleResetRM}>
      <div className="activated-offer-details-container">
        <div className="activated-offer-details-top">
          <img src={data.offerLogo} alt="offer" />
          <div className="activated-offer-details-top--info">
            <div className="activated-offer-details-top--info-name">{data.offerName}</div>
            <div className="activated-offer-details-top--info-des">{data?.earnOfferLang}</div>
            <div className="activated-offer-details-top--info-des">
              Valid: {moment(data?.offerExpiryDate)?.format('ddd, MM/DD')}
            </div>
            <div className="activated-offer-details-top--info-des">Auto-activated: Not Yet</div>
          </div>
        </div>
        <div className="activated-offer-details-card">
          <img src={data?.cardImageUrl} alt="card" />
          <div>{data?.cardName}</div>
        </div>
        <div className="activated-offer-details-date-left">
          {dateLeft > 0 ? `${dateLeft} ${dateLeft > 1 ? 'days left' : 'day left'}` : 'Expired'}
        </div>
        <div className="activated-offer-details-sub">Offer Details</div>
        <div className={`activated-offer-details-description ${showMore ? 'show' : 'hidden'}`}>
          {data?.offerDescription}
        </div>
        {data?.offerDescription.length > 300 && (
          <button className={`btn-readmore ${showMore ? 'hidden' : 'show'}`} onClick={() => setShowMore(!showMore)}>
            Read more
          </button>
        )}
      </div>
    </ModalCustom>
  );
};
