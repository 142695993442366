import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LoginExtension: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('data') || '';
    const refreshToken = params.get('refresh') || '';
    const deviceKey = params.get('deviceKey') || '';

    if (token && refreshToken && deviceKey) {
      setTimeout(() => {
        window?.postMessage({ type: 'LOGGED_USER', token, refreshToken, deviceKey }, '*');
        navigate('/enable-extension');
      }, 3000);
    }
  }, [location]);

  return null;
};

export default LoginExtension;
