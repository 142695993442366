export const endpoints = {
  register: '/auth/v2/register',
  registerSocial: '/auth/v2/register-social',
  registerVerify: '/auth/register-verify-data',
  verifyCognitoUser: '/auth/v2/register/verifyCognito',
  getUserByEmail: '/auth/get-user',
  getMe: '/user',
  getCategories: '/estimate-reward/v2/personalized-offer-saving-display-categories',
  getCategoriesForAll: '/estimate-reward/v2/display-categories-on-display-category-all',
  getOffersByCategory: '/estimate-reward/v2/offer-merchants-by-display-category',
  searchOffer: '/estimate-reward/v2/offer-merchants/search',
  offerMerchantDetails: '/estimate-reward/v2/by-merchant',
  cards: '/recommended-card/v3/featured-cards',
  recommendedCard: '/recommended-card/v3/suggested-cards',
  pinMerchant: '/estimate-reward/favorite-merchant/add',
  unPinMerchant: '/estimate-reward/favorite-merchant/remove',
  reactionCard: '/recommended-card/v3/user-like-response',
  latestOffers: '/estimate-reward/v2/featured-affiliate-offer-wall',
  totalSpending: '/spending/total',
  rewardSummary: '/reward/summary',
  availableRewards: '/reward-balance/summary',
  recommendedCardAll: '/recommended-card/v3/all',
  compareRewards: '/estimate-reward/v2/compare-rewards',
  bankOfferCategories: '/offers/categories/me',
  storeOfferCategories: '/merchant-offers/stores/me',
  bankOffers: '/offers/me',
  storeOffers: '/merchant-offers/me',
  countBankOffers: '/offers/me/count',
  countStoreOffers: '/merchant-offers/me/count',
  bankOfferWorth: '/offer-redeemed',
  storeOfferWorth: '/merchant-offer-redeemed/me',
  banks: 'offers/institution-shortname/me',
  favBank: '/offers/me/pin-unPin',
  totalCommissions: '/commissions/offer-merchants/total',
  offersMerchantMySavings: '/commissions/offer-merchants',
  redeemOffer: '/commissions/offer-merchants/redeem',
  redeemHistory: '/commissions/payout/histories',
  redeemDetail: 'commissions/payout',
  missedRewards: '/reward/missed',
  earnedRewards: '/reward/earned',
  missedRewardDetail: '/reward/missed-detail',
  getProfile: '/profile/get-profile',
  userConfig: '/user-subscriptions/config',
  contactUs: '/contact-us',
  getBanks: '/manual-card/featured-bank',
  searchBank: '/manual-card/search-bank',
  bankSubtype: '/manual-card/bank-subtype',
  bankSubtypeCards: '/manual-card/items-bank-subtype',
  saveCard: '/manual-card/save',
  deleteAccount: '/user/delete-account',
  sendOTP: '/user/send-otp',
  verifyUserInfo: '/user/v2/update-account-verification',
  wrongRewardEarn: '/reward/feedback/user-wrong-category',
  consentCapture: '/auth/v2/register-social/accept-consent',
  trackingFeedback: 'user/v3/tracking-feedback',
  userSurvey: '/user-survey',
  userSurveySave: '/user-survey/save-response',
  spendingByCategory: '/spending/by-category',
  spendingByGroup: '/spending/by-merchant-group',
  spentCategoryByMonth: '/spending/by-category/by-month',
  spentCategoryTopEarned: '/spending/by-category/top-earned',
  spentByMerchant: '/spending/by-merchants',
  feedbackUninstallExtension: 'extension/delete-feedback',
  shadowId: 'user/get-shadow-user-id',
  getLinkPlaid: 'plaid/getLink',
  validatePlaid: 'plaid/validate',
  tokenPlaid: 'plaid/token',
  validatePlaidLayer: '/plaid/validate-layer',
  linkedAccount: 'linked-account',
  linkedAccountMappingFailed: '/linked-account/mapping-failed',
  linkedAccountCard: 'linked-account/card',
  linkedAccountUpdateCard: 'linked-account/update-card',
  linkedAccountCalcualteReward: 'linked-account/calculate-reward',
  plaidUnlinkAccount: 'plaid/unlink-account',
  plaidRelinkAccount: 'plaid/relink-account',
  plaidRelink: 'plaid/reLink',
  refreshBank: 'plaid/syncData',
  clickTracking: 'user/click-tracking',
  sendLinkDownloadApp: 'user/send-link-download-app',
  loginSuccessTracking: '/auth/track-login-success',
  getSuggestedOffer: 'offers/suggested'
};
